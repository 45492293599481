<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">이벤트</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 369px" />
            <col style="width: 120px" />
            <col style="width: 369px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">제목</td>
            <td class="box_style_02" colspan="3">
              {{ title }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">내용</td>
            <td class="box_style_03" colspan="3">
              {{ text }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">커버 이미지</td>
            <td class="box_style_03">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="커버 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
          </tr>
          <!-- <tr class="line_style">
            <td class="box_style_02 list_title_style">태그</td>
            <td class="box_style_02" colspan="3">
              {{ type == "new" ? tag : content.tag }}
            </td>
          </tr> -->
          <tr class="line_style">
            <td class="box_style_02 list_title_style">링크</td>
            <td class="box_style_02" colspan="3">
              {{ url }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    type: {
      type: String,
      default: () => "",
    },
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      title: "",
      text: "",
      cover: {},
      tag: "",
      url: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.type == "new") {
        await client.eventFindone(this.id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            this.title = data.title;
            this.text = data.text;
            this.cover = data.cover;
            // this.tag = data.tag;
            this.url = data.url;
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        await client.oldBannerFindone(this.id).then(
          (response) => {
            const { data } = response;
            console.log(data);
            this.title = data.content.title;
            this.text = data.content.text;
            this.cover = data.content.cover;
            // this.tag = data.content.tag;
            this.url = data.content.url;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
  },
};
</script>
